import $ from 'jquery';
// import jquery from 'jquery';

// import bootstrap from 'bootstrap';

// import { popper } from '@popperjs/core';
require('popper.js');
require('bootstrap');

// COMPONENTS
// require('./general.js');
// require('./components/dropdown.js');
// require('./components/olw-carousel2.js');
// require('./components/overlay-menu.js');
// require('./components/mapplace.js');
// require('./components/smooth-scroll.js');
// require('./components/sticky-kit.js');
// require('./components/edittable.js');
// require('./components/collapse.js');
// require('./components/modal.js');

/**
 * Seleciona los imputs del sistema y los comvierte a imputs con etiquetas flotantes
 */
// $('.form-control').on('input', function() {
//   var $field = $(this).closest('.form-group');
//   if (this.value) {
//     $field.addClass('form-control--not-empty');
//   } else {
//     $field.removeClass('form-control--not-empty');
//   }
// });



/**
* forEach implementation for Objects/NodeLists/Arrays, automatic type loops and context options
*
* @private
* @author Todd Motto
* @link https://github.com/toddmotto/foreach
* @param {Array|Object|NodeList} collection - Collection of items to iterate, could be an Array, Object or NodeList
* @callback requestCallback      callback   - Callback function for each iteration.
* @param {Array|Object|NodeList} scope=null - Object/NodeList/Array that forEach is iterating over, to use as the this value when executing callback.
* @returns {}
*/
var forEach=function(t,o,r){if("[object Object]"===Object.prototype.toString.call(t))for(var c in t)Object.prototype.hasOwnProperty.call(t,c)&&o.call(r,t[c],c,t);else for(var e=0,l=t.length;l>e;e++)o.call(r,t[e],e,t)};

var hamburgers = document.querySelectorAll(".hamburger");

if (hamburgers.length > 0) {
    forEach(hamburgers, function(hamburger) {
        hamburger.addEventListener("click", function() {
            this.classList.toggle("is-active");
        }, false);
    });
}

$(document).ready(function(){
    $('#star-notification').modal('show')
});

// console.log(inputs);

// require('./login.js');

//require('perfect-scrollbar');

